import React, { Component } from "react";

class Brand extends Component{
    render(){
        const {branstyle } = this.props;
        return(
            <React.Fragment>
                <ul className={`brand-list ${branstyle}`}>
                    <li>
                        <img src="/assets/images/brand/brand-05.png" alt="MITSUBISHI"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-09.png" alt="SINFONIA"/>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default Brand;