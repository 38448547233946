
let data = [
    {
        images: '01',
        title: '',
        designation: '',
        socialNetwork: [
        ]
    },
    {
        images: '02',
        title: '',
        designation: '',
        socialNetwork: [
        ]
    },
    {
        images: '03',
        title: '',
        designation: '',
        socialNetwork: [
        ]
    },
    {
        images: '04',
        title: '',
        designation: '',
        socialNetwork: [
        ]
    },
    {
        images: '05',
        title: '',
        designation: '',
        socialNetwork: [
        ]
    },
    {
        images: '06',
        title: '',
        designation: '',
        socialNetwork: [
        ]
    },
    {
        images: '07',
        title: '',
        designation: '',
        socialNetwork: [
        ]
    },
    {
        images: '08',
        title: '',
        designation: '',
        socialNetwork: [
        ]
    },
    {
        images: '09',
        title: '',
        designation: '',
        socialNetwork: [
        ]
    },
    {
        images: '11',
        title: '',
        designation: '',
        socialNetwork: [
        ]
    },
    {
        images: '12',
        title: '',
        designation: '',
        socialNetwork: [
        ]
    },
    {
        images: '13',
        title: '',
        designation: '',
        socialNetwork: [
        ]
    },
    {
        images: '14',
        title: '',
        designation: '',
        socialNetwork: [
        ]
    },
    {
        images: '15',
        title: '',
        designation: '',
        socialNetwork: [
        ]
    },
    {
        images: '16',
        title: '',
        designation: '',
        socialNetwork: [
        ]
    },
    {
        images: '17',
        title: '',
        designation: '',
        socialNetwork: [
        ]
    },
    {
        images: '18',
        title: '',
        designation: '',
        socialNetwork: [
        ]
    },
    {
        images: '19',
        title: '',
        designation: '',
        socialNetwork: [
        ]
    },
    {
        images: '20',
        title: '',
        designation: '',
        socialNetwork: [
        ]
    },
    {
        images: '21',
        title: '',
        designation: '',
        socialNetwork: [
        ]
    }
];

export default data;